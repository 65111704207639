<template>
  <div>
    <span class="mr-20">{{ $t('jobPosition.createNewJob') }}</span>
    <b-button
      variant="primary"
      class="btn-icon rounded-circle"
      :style="colorPrimaryBtn"
      @click="showJobModal = true"
    >
      <feather-icon icon="PlusIcon" />
    </b-button>
    <b-modal
      id="createJobModal"
      v-model="showJobModal"
      centered
      ok-only
      :title="$t('jobPosition.createNewJob')"
      no-close-on-backdrop
      :ok-title="$t('buttons.save')"
      @close="resetForm"
    >
      <validation-observer ref="createJobRules">
        <b-form ref="formRegister">
          <!-- Club -->
          <b-form-group v-if="!checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
            <b>{{ $t('corporate.club') }}: </b> <label>{{ clubInfo.club_name }} </label>
          </b-form-group>
          <!-- Name -->
          <b-form-group>
            <label for="name">{{ $t('generic.name') }} <span class="text-danger">*</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('generic.name')"
            >
              <b-form-input
                id="name"
                v-model="job.name"
                type="text"
                :placeholder="$t('jobPosition.enterJobPosition')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Country -->
          <b-form-group v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
            <label for="club-country-id">
              {{ $t('generic.country') }}
              <span class="text-danger">*</span>
            </label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('generic.country')"
            >
              <v-select
                id="club-country-id"
                v-model="options.country_id"
                label="name"
                :options="countries"
                :reduce="(country) => country.id"
                :placeholder="$t('generic.selectCountry')"
              >
                <div slot="no-options">
                  {{ $t('generic.noRecordsFound') }}
                </div>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- State -->
          <b-form-group v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
            <label for="club-state-id">
              {{ $t('generic.state') }}
              <span class="text-danger">*</span>
            </label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('generic.state')"
            >
              <v-select
                id="club-state-id"
                v-model="options.state_id"
                label="name"
                :options="states"
                :disabled="options.country_id == null"
                :reduce="(states) => states.id"
                :placeholder="$t('generic.selectState')"
              >
                <div slot="no-options">
                  {{ $t('generic.noRecordsFound') }}
                </div>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- club -->
          <b-form-group v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
            <label for="name">{{ $t("corporate.club") }} <span class="text-danger">*</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('corporate.club')"
            >
              <v-select
                v-model="job.club_id"
                :placeholder="$t('corporate.selectSportClub')"
                label="name"
                :options="clubs"
                :disabled="options.state_id == null"
                :reduce="(club) => club.id"
              >
                <div slot="no-options">
                  {{ $t('generic.noRecordsFound') }}
                </div>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="float-right">
          <b-button
            variant="secondary"
            pill
            @click="resetForm"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-overlay
            :show="isLoadingCreate"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              variant="primary"
              style="margin-left: 17px;"
              pill
              :style="colorPrimaryBtn"
              @click="saveJobData"
            >
              {{ $t('buttons.save') }}
            </b-button>
          </b-overlay>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import servicesCountry from '@/services/countryService'
import servicesClubs from '@/services/clubsService'
import servicesJob from '@/services/jobService'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  props: {
    countries: {
      type: Array,
      default: null,
    },
    clubInfo: {
      type: Object,
      required: true,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showJobModal: false,
      isLoadingCreate: false,
      required,
      filters: {
        page: 1,
        perPage: 15,
        idClub: null,
      },
      job: {
        name: null,
        club_id: null,
      },
      options: {
        country_id: null,
        state_id: null,
      },
      states: [],
      clubs: [],
    }
  },

  watch: {
    'options.country_id': function (pCountry, pOld) {
      if (pCountry != null) {
        if (pOld != null) {
          if (pOld != pCountry) {
            this.states = []
            this.options.state_id = null
            this.getStates(pCountry)
          }
        } else {
          this.states = []
          this.options.state_id = null
          this.getStates(pCountry)
        }
      } else {
        this.states = []
        this.options.state_id = null
      }
    },
    'options.state_id': function (pState, pOldS) {
      if (pState != null) {
        if (pOldS != null) {
          if (pOldS != pState) {
            this.clubs = []
            this.job.club_id = null
            this.getClubs(pState)
          }
        } else {
          this.clubs = []
          this.job.club_id = null
          this.getClubs(pState)
        }
      } else {
        this.clubs = []
        this.job.club_id = null
      }
    },
  },

  mounted() {
    this.clearForm()
  },

  methods: {
    getStates(countryHash) {
      const filter = { country_hash: countryHash }
      servicesCountry.getStates(filter).then(({ data }) => {
        this.states = data.data.map(e => ({ id: e.hash, name: e.name }))
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    getClubs(stateHash) {
      const filter = {
        state_hash: stateHash,
        status: 1,
      }
      servicesClubs.getClubs({}, filter).then(({ data }) => {
        this.clubs = data.data.map(e => ({ id: e.hash, name: e.club_name }))
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    saveJobData() {
      if (this.job.club_id == null) {
        this.job.club_id = this.clubInfo.hash
        // this.job.sport_club_id = this.clubInfo.hash
      }
      this.$refs.createJobRules.validate().then(isValid => {
        if (isValid) {
          servicesJob.storeJob(this.job).then(({ data }) => {
            this.$emit('successfully')
            this.responseSuccessCreate(data.message)
            this.resetForm()
          }).catch(error => {
            this.responseCatch(error)
          })
        }
      })
    },
    resetForm() {
      this.showJobModal = false
      this.filters.idClub = this.clubInfo.hash
      this.clearForm()
    },
    clearForm() {
      this.job.name = null
      this.job.club_id = null
      this.options.country_id = null
      this.options.state_id = null
    },
  },
}
</script>
<style scoped lang="scss">
.mr-20 {
  margin-right: 20px !important;
}
</style>
