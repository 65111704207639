import apiInstance from './index'
import queryParams from './queryParams'

export default {
  async getClubs(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`clubs${query}`)
  },

  async getClubsByRole() {
    return await apiInstance.get('clubs/by-role')
  },

  async storeClub(data) {
    return await apiInstance.post('clubs', data)
  },

  async updateClub(data, id) {
    return await apiInstance.put(`clubs/${id}`, data)
  },

  async statusClubs(club) {
    return await apiInstance.put(`clubs/status/${club}`)
  },

  async getClubInfo(id) {
    return await apiInstance.get(`clubs/${id}`)
  },

  async getUsersByClubId(id) {
    return await apiInstance.get(`clubs/${id}/users`)
  },

  async getRolesByClubId(id) {
    return await apiInstance.get(`clubs/${id}/roles`)
  },

  async getJobPositionsByClubId(id) {
    return await apiInstance.get(`clubs/${id}/job-positions`)
  },

  async getInstrumentNumbersByClubId(id) {
    return await apiInstance.get(`clubs/${id}/instrument-numbers`)
  },

  async switchCurrentClub(id, clubId) {
    return await apiInstance.get(`clubs/${id}/switch-by/${clubId}`)
  },

  async getRepresentativesByClubId(id) {
    return await apiInstance.get(`clubs/${id}/representatives`)
  },

  async getCurrentSocietyByClubId(id) {
    return await apiInstance.get(`clubs/${id}/current-society`)
  },

  async getRegisterNumbersByClubId(id) {
    return await apiInstance.get(`clubs/${id}/register-numbers`)
  },
}
